export const environment = {
  production: false,
  baseHref: '/merchandising-portal/',
  clientId: 'c287a390-2cf2-42cb-a671-7a87485c9bea',
  backendApiScope: 'api://82941729-9bb7-4710-b5d3-cdc4475a29dc/access_as_user',
  apiResourceUri:
    'https://crm-api-uk.dev.ukg2go.agpre.net/api/',
  reportingUrl: 'https://reporting-ui-uk.dev.ukg2go.agpre.net/reporting/',
  find: 'activity/find',
  cloudFrontRequests: '.cloudfront.net/',
  imagesBucket: 'images',
  resizedImagesBucket: 'resized-images',
  dateFormatUS: 'dd/MM/yyyy',
  dateFormatUK: 'dd/MM/yyyy',
  lengthValidators: {
    title: 100,
    description: 1500,
    comment: 100,
    response: 100,
    titleOfSet: 60
  },
  maxFileUploadSize: 3,
  title: 'UK2GO Merchandising Portal',
  landingLogoPath: 'assets/img/ukg-logo-large.png',
  sidenavLogoPath: 'assets/img/ukg-logo-min.png',
  shipperName: 'Shipper Count',
  emojiRegEx : '^[A-Za-z0-9\\s+!@#$%^&*()-–_+=`~\\\]\[{}|\';:/.,?><]*$'
};
