import {Component, OnInit} from '@angular/core';
import {FileNode} from '../../../shared/components/generic-file-structure/generic-file-structure.component';
import {AttachmentView} from '../../../shared/models/attachment.config';

@Component({
  selector: 'app-documents-structure',
  templateUrl: './documents-structure.component.html',
  styleUrls: ['./documents-structure.component.scss']
})
export class DocumentsStructureComponent implements OnInit {

  uploadPath: string;
  nodes: FileNode[] = [];

  showUpload = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  buildConfig() {
    return {
      view: AttachmentView.ADMIN_DOCUMENTS,
      isArchived: false
    };
  }

  uploadView(path: string) {
    this.uploadPath = path;
    this.showUpload = true;
  }

  saveFileStructureState(nodes: FileNode[]) {
    this.nodes = nodes;
  }

  fileView(event: boolean) {
    this.showUpload = event;
  }

}
